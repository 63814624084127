import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { FooterMenu } from "./footer-menu/footer-menu"
import { RegionToggle } from "../../modules/region-toggle/region-toggle"

import "./footer.scss"

export const Footer = () => {
  const { wagtail } = useStaticQuery(
    graphql`
      query {
        wagtail {
          pages {
            ... on GridPage {
              title
              url
              urlLabel
              sitearea
            }
            ... on BasicPage {
              title
              url
              urlLabel
              sitearea
            }
          }
        }
      }
    `
  )

  const mapAndFilter = (pages, filter) =>
    pages
      .filter(page => page && page.sitearea === filter)
      .map(({ title, urlLabel, url }) => ({
        label: urlLabel || title,
        url,
      }))

  const AboutUsLinks = [
    ...mapAndFilter(wagtail.pages, "about"),
    {
      label: "Working for us",
      url: "https://www.moneyadvicetrust.org/about-us/careers/?#vacancies",
    },
  ]

  const ContactUsLinks = [
    {
      label: "Agencies",
      url: "http://www.moneyadvicetrust.org/advice-agencies",
    },
    {
      label: "Media",
      url: "http://www.moneyadvicetrust.org/media/pages/media-contacts.aspx",
    },
    ...mapAndFilter(wagtail.pages, "contact"),
  ]

  const PolicyLinks = mapAndFilter(wagtail.pages, "policy")

  const CountryLinks = [
    {
      label: "Advice in Northern Ireland",
      url: "/northern-ireland",
    },
  ]

  const DebtAdviceLinks = [
    {
      label: "My Budget",
      url: "https://mybudget.businessdebtline.org/",
    },
    {
      label: "Fact sheets",
      url: "/fact-sheet-library",
    },
    {
      label: "Sample letters",
      url: "/sample-letters",
    },
    {
      label: "AdviserHub",
      url: "https://adviser-hub.businessdebtline.org/",
    },
    ...mapAndFilter(wagtail.pages, "debt_advice"),
  ]

  const SocialMediaLinks = [
    {
      label: "Facebook",
      url: "https://www.facebook.com/businessdebtline",
      className: "facebook",
    },
    {
      label: "Twitter",
      url: "https://twitter.com/Biz_Debtline",
      className: "twitter",
    },
  ]

  return (
    <footer className="footer d-print-none" id="footer" aria-label="footer">
      <div className="container pt-4 pb-4 pb-sm-5 text-center text-sm-left">
        <nav className="row m-0" aria-label="footer navigation">
          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 pr-2">
            <h2 className="footer-title">About us</h2>
            <FooterMenu links={AboutUsLinks} />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 py-2 pt-sm-0 py-lg-0 px-lg-2">
            <h2 className="footer-title">Debt advice</h2>
            <FooterMenu links={DebtAdviceLinks} />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 py-2 py-md-0 px-lg-2">
            <h2 className="footer-title">Contact us</h2>
            <FooterMenu links={ContactUsLinks} />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 py-2 py-lg-0 px-lg-2">
            <h2 className="footer-title">Policies</h2>
            <FooterMenu links={PolicyLinks} />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 py-2 py-lg-0 px-lg-2">
            <h2 className="footer-title mt-2">Follow us</h2>
            <FooterMenu links={SocialMediaLinks} />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg p-0 mt-2 mt-sm-0 pl-lg-2">
            <h2 className="footer-title">My Budget</h2>

            <a
              href="https://mybudget.businessdebtline.org/"
              className="btn btn-primary w-100 chevron-icon py-1"
              aria-label="Open My Budget"
            >
              Register/sign in
            </a>

            <h2 className="footer-title mt-2">Your jurisdiction</h2>
            <RegionToggle className="mb-2" />
            <FooterMenu className="mt-2" links={CountryLinks} />
          </div>
        </nav>
      </div>

      <div className="footer-legal">
        <div className="container">
          <div className="row text-center text-xl-left">
            <div className="col-12 col-xl-12 mb-3 mb-xl-0 text-xl-center">
              Business Debtline is a debt advice service run by the Money Advice
              Trust. The Money Advice Trust is a registered charity: no.
              1099506.
              <br />
              A company limited by guarantee Registered in England and Wales:
              no. 4741583. Registered office: 21 Garlick Hill, London EC4V 2AU
              <br />
              <a
                href="https://www.fca.org.uk/"
                className="footer-legal-link"
                aria-label="Financial Conduct Authority"
              >
                Authorised and regulated by the Financial Conduct Authority - 
                618928
              </a>
              <br />
              Copyright © 2024
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
